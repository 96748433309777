import React, { useState, useEffect } from 'react';
import './App.css';
import { MDBContainer, MDBRow, MDBCol, MDBRipple } from 'mdb-react-ui-kit';

import Header from './Header';
import firebase from './FirebaseConfig';
import CreateVerifyUser from "./auth/CreateVerifyUser";


function Main() {
    const [accessToken, setAccessToken] = useState(null);
    const [loginBtnText, setLoginBtnText] = useState("login");
    const [validToken, setValidToken] = useState(false);
    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const accessToken = queryParams.get('page');
        if (accessToken) {
            setAccessToken(accessToken);
            verifyToken(accessToken);

        }
        else {
            setLoginBtnText("Login");

        }
    }, []);

    const verifyToken = (token) => {
        firebase.auth().signInWithCustomToken(token)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log('Token is valid for user', user.uid);
                setLoginBtnText("Logout");
                setValidToken(true);
                // Do something with the verified user information
            })
            .catch((error) => {
                console.error('Error verifying token:', error);
                setLoginBtnText("Login");
                setAccessToken(null);
                setValidToken(false);
            });
    };

    return (
        <div>
        <Header loginBtnText={loginBtnText} />
            <div style={{backgroundImage: 'url("img-copy-1.jpg")', backgroundSize: 'cover', height: '100vh'}}>

                <div className="d-flex align-items-center justify-content-center" style={{ minHeight: 'calc(100vh - 200px)', display: 'flex' }}>
                {validToken ? (
                        <h1>Main</h1>
                    ) : (
                        <CreateVerifyUser />
                    )}
                    {/* Your other main content goes here */}
                </div>
            </div>
        </div>
    );
}

export default Main;