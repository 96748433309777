// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC2ju7nAdQVSEyqjfoKfSaMHLeYOrJhRuE",
    authDomain: "trkd-355913.firebaseapp.com",
    projectId: "trkd-355913",
    storageBucket: "trkd-355913.appspot.com",
    messagingSenderId: "863041149962",
    appId: "1:863041149962:web:4b2a47bf177c5331d313f3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();

export default firebase;