import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBBtn,
    MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav
} from 'mdb-react-ui-kit';
import logo from './logo.png'

function Header({loginBtnText,navBarColour}) {
    const [showBasic, setShowBasic] = useState(false);
    const [showLoginButton, setShowLoginButton] = useState("Login");

    return (
        <div>
            <header>
                <MDBNavbar light={navBarColour === 'light'} dark={navBarColour === 'dark'} >
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='https://www.trkd.io'>
                            <img
                                src={logo}
                                height='30'
                                alt=''
                                loading='lazy'
                            />
                        </MDBNavbarBrand>
                        <MDBNavbarBrand tag="form" className='d-flex w-auto mb-3'>
                            <MDBBtn ><MDBIcon icon='user' /> {loginBtnText}</MDBBtn>
                        </MDBNavbarBrand>
                    </MDBContainer>
                </MDBNavbar>

            </header>
        </div>
    )
}

export default Header;
