import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Main from './Main';
import Dashboard from "./Dashboard";


const App = () => (
    <Router>
        <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
        </Routes>
    </Router>
);


export default App;