import React, {useState, useEffect, useCallback} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody, MDBCardFooter, MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBInput, MDBInputGroup,
    MDBRow,
    MDBTypography
} from "mdb-react-ui-kit";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import firebase from '../FirebaseConfig'
import {createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";
import {useLocation, useNavigate} from 'react-router-dom';
import "../App.css"

function CreateVerifyUser() {
    const location = useLocation();
    const history = useNavigate();

    const [loadCapthaOnceCnt, setLoadCapthaOnceCnt] = useState(0);

    let [mobileCode, setMobileCode] = useState('');
    let [countryCode, setCountryCode] = useState('');
    let [mobile, setMobile] = useState('');
    let [confirmationResult, setConfirmationResult]= useState({});
    const [showMobileCodeInput, setShowMobileCodeInput] = useState(false);

// Initialize Firebase Authentication and get a reference to the service
    const submitCode = event => {
        const code = mobileCode;
        // console.log("Code is " + code);
        confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            const userDetails = result.user;
            console.log("user ", userDetails);
            history('/dashboard', { state: {    referenceNumber: "chmtest",
                                                accessToken: userDetails.stsTokenManager.accessToken}});
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            //console.log("error ", error);

        });
    }

    const createPhone = (phoneNumber) => {

        console.log("createPhone");
        const auth = firebase.auth();
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult);
                //console.log("*********** confirmationResult **************", confirmationResult);
                //appVerifier.clear();
            }).catch((error) => {
            // Error; SMS not sent
            console.log("*********** ERROR ************** : "+error.message);
        });


    }

    const trkdRecaptchaVerifier = (phoneNumber) => {
        console.log("trkdRecaptchaVerifier");

        const auth = firebase.auth();;

        if (!window.recaptchaVerifier) {
            try{
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        //  onSignInSubmit();
                        console.log("RES ", response)
                        //createPhone(auth,phoneNumber,response)
                    },
                    'expired-callback': () => {
                        console.log("expired-callback ");
                    }
                }, auth);
            }
            catch(e) {
                console.log("We had a error",e);
            }

        }
    }





    const reSendCode = event => {
        //window.recaptchaVerifier.clear();
        trkdRecaptchaVerifier();
        //createPhone();
    }



    const getAccessCode = event => {
        console.log("Country : " + countryCode);
        console.log("mobile : " + mobile);

        let phoneNumber = formatMobile(countryCode,mobile);
        console.log("phoneNumber : " + phoneNumber);
        trkdRecaptchaVerifier(phoneNumber);
        createPhone(phoneNumber);

        setShowMobileCodeInput(true);
    }

    const onChangeMobileCodeHandler = event => {
        setMobileCode(event.target.value);
    };

    const formatMobile = (countryCode,mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');


        return "+"+countryCode+mobile;

    }
    const formatMobileOnFly = (mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');

        console.log("MOBILE OUT : " + mobile);
        return mobile;

    }
    const onChangeMobileHandler = event => {
        setMobile(event.target.value);
        console.log("Mobile : " + event.target.value);
    };

    const onChangeCountryCodeHandler = event => {
        setCountryCode(event.target.value);
        console.log("Country Code : " + event.target.value);
    }

        return (
            <div className='w-100'>

                <div id="recaptcha-container"></div>
                <div className='w-100'><br/></div>
                <MDBContainer breakpoint="xxl" style={{ border: '0px solid black' }}>


                <MDBRow className='justify-content-center'>
                        <MDBCol size='11' md='5'>
                                    <MDBCard border='primary'  shadow='10' alignment='center' className='mb-3'>
                                        <MDBCardHeader><h3>Account Login</h3></MDBCardHeader>
                                        <MDBCardBody>

                                            {!showMobileCodeInput ? (
                                            <MDBRow>
                                                <MDBContainer fluid>


                                                        <Row>
                                                            <Col xs={5}>
                                                        <Form.Select label="Country Code" size="lg" onChange={onChangeCountryCodeHandler} >
                                                            <option value="" >country code</option>
                                                            <option value="44" >+44</option>
                                                        </Form.Select>
                                                            </Col>
                                                            <Col xs={7}>
                                                        <MDBInput
                                                            label="mobile"
                                                            id="mobile"
                                                            type="text"
                                                            name="mobile"
                                                            value={mobile}
                                                            onChange={onChangeMobileHandler}
                                                            size="lg"
                                                        />
                                                            </Col>
                                                        </Row>



                                                    <div className='w-100'><br/></div>
                                                    <MDBBtn  onClick={getAccessCode} >Get Access Code</MDBBtn>
                                                </MDBContainer>

                                            </MDBRow>) : null }

                                            <div className='w-100'><br/></div>
                                            <MDBRow>

                                                    {showMobileCodeInput ? (
                                                        <MDBContainer fluid>
                                                        <Row>
                                                            <Col xs={15}>

                                                            <MDBInput
                                                                label="mobileCode"
                                                                id="mobileCode"
                                                                type="text"
                                                                name="Mobile Code"
                                                                value={mobileCode}
                                                                onChange={onChangeMobileCodeHandler}
                                                                size="lg"
                                                            />
                                                            </Col>
                                                        </Row>

                                                    <div className="d-flex justify-content-center">
                                                        <div className="p-2" ><MDBBtn  onClick={submitCode} >submit Code</MDBBtn></div>
                                                        <div className="p-2"><MDBBtn  onClick={reSendCode} >Re-send OTP</MDBBtn></div>
                                                    </div>

                                                    </MDBContainer>
                                                        ) : null

                                                    }

                                            </MDBRow>
                                        </MDBCardBody>
                                        {showMobileCodeInput ? (
                                        <MDBCardFooter className='text-muted'>
                                            <MDBTypography note noteColor='success'>
                                                <strong>One Time Passcode: </strong>
                                                is valid for 15 minutes
                                            </MDBTypography>
                                        </MDBCardFooter>
                                        ) : null}

                                    </MDBCard>

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );

    };
export default CreateVerifyUser;